import React, {Component} from 'react';
import style from './style.module.scss'
import './select.scss'
import Header from './components/Header'
import Block from './components/Block';
import FontFamilies from './components/FontFamilies'
import Typography from './components/Typography'
import Colors from './components/Colors'
import Links from './components/Links'
import Menu from './components/Menu'
import Icons from './components/Icons'
import Buttons from './components/Buttons'
import Fields from './components/Fields'


import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import { Container } from '@material-ui/core';

const CustomTool = withStyles(theme => ({
    tooltip: {
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 13,

        padding: '5px 10px',
        top: '10px'
    },
}))(Tooltip);


class StyleGuide extends Component {
    componentDidMount() {
        document.title = "Styleguide page"
    }
    render() {
        return(
            <div className={style.styleguide}>
                <Container className={style.container}>
                    <Header/>

                    <Block>
                        <h2>Font families (can be downloaded on fonts.google.com)</h2>
                        <FontFamilies family="Overpass" familyStyle={"Overpass"} styles={["SemiBold 600"]} paddingW="19px" paddingWs="14px"/>
                        <FontFamilies family="Source Sans Pro" familyStyle={"Source Sans Pro"} fontWeight={"400"} styles={["Regular 400","Bold 700"]}/>
                    </Block>

                    <Block>
                        <h2>Typography</h2>
                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 1" styleW="SemiBold 600" resize="(desktop)" weight="600" size="50" lineheight="60" paddingW="4px"  lineheightCaption="51"/>
                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 2" styleW="SemiBold 600" resize="(desktop, tablet)" size="42" weight="600"  paddingW="3px"  lineheight="51"/>
                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 3" styleW="SemiBold 600" resize="(desktop, tablet)" size="26" weight="600" padding="4px" paddingW="8px"  lineheight="31"/>
                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 4" styleW="SemiBold 600" resize="(desktop, tablet, mobile)" weight="600" padding="5px" size="22" paddingW="8px"  lineheight="26"/>
                        <Typography family="Source Sans Pro" familyStyle="Source Sans Pro" heading="Heading 5" styleW="Regular 400" resize="(desktop, tablet)" padding="5px" weight="400" size="18" lineheight="21"/>

                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 1" styleW="SemiBold 600" weight="600" resize="(tablet)" size="42" lineheight="51" paddingW="5px"  lineheightCaption="51"/>
                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 1" styleW="SemiBold 600" weight="600" resize="(mobile)" size="30" padding="5px" lineheight="36"/>
                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 2" styleW="SemiBold 600" weight="600" resize="(mobile)" size="26" padding="3px" paddingW="7px" lineheight="31"/>
                        <Typography family="Overpass" familyStyle="Overpass" heading="Heading 3" styleW="SemiBold 600" weight="600" resize="(mobile)" size="22" padding="5px" lineheight="26" paddingW="8px" />
                        <Typography family="Source Sans Pro" familyStyle="Source Sans Pro" heading="Heading 5" styleW="Regular 400" weight="400" resize="(mobile)" padding="5px" size="16" lineheight="20"/>

                        <Typography family="Source Sans Pro" familyStyle="Source Sans Pro" heading="Paragraph 1" styleW="Regular 400" weight="400" resize="" size="18" lineheight="26" lineheightStyle="26" text="
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    "
                                    styled={{paddingTop: '33px', width: '735px', transform: 'translateX(-9px)'}} styledW={{paddingTop: '33px', width: '755px', transform: 'translateX(10px)' }}
                        />

                        <Typography family="Source Sans Pro" familyStyle="Source Sans Pro" heading="Paragraph 2" styleW="Regular 400" weight="400" resize="" size="16" lineheight="22" lineheightStyle="23" text="
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.                     "
                                    styled={{paddingTop: '33px', width: '755px', transform: 'translateX(11px)' }} styledW={{paddingTop: '33px', width: '755px', transform: 'translateX(10px)' }}padding="1px"
                        />

                        <Typography family="Source Sans Pro" familyStyle="Source Sans Pro" heading="Paragraph 3" styleW="Regular 400" weight="400" resize="" size="14" lineheight="20" lineheightStyle="20"text="
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.                     "
                                    styled={{paddingTop: '34px'}} styledW={{paddingTop: '34px'}}
                                    padding="1px"
                        />
                    </Block>

                    <Block>
                        <h2>Main colors</h2>
                        <Colors color="Primary color" rgb="239 / 108 / 0" hex="#ef6c00"/>
                        <Colors color="Secondary color" rgb="40 / 53 / 147" hex="#283593"/>
                        <Colors color="Background color" rgb="255 255 255" hex="#fff" border="1px solid #d0d0d0" styled={{height: '118px', width: '724px'}}/>
                    </Block>

                    <Block>
                        <h2>Links</h2>
                        <div className={style.links}>
                            <Links type="dark" text="Link" href="#"/>
                            <Links type="" text="Link" href="#"/>
                            <Links type="big" text="Text" href="#"/>
                        </div>
                    </Block>

                    <Block>
                        <h2>Menu</h2>
                        <Menu/>
                    </Block>

                    <Block>
                        <h2>Tooltips</h2>
                        <div className={style.tooltips}>
                            <CustomTool title="verylongtext">
                                <p>verylong...</p>
                            </CustomTool>
                            <p></p>
                        </div>
                    </Block>

                    <Block>
                        <h2>Icons</h2>
                        <div className={style.icons}>
                            <p>Primary color</p>
                            <div className={style.status}>
                                <p>Active</p>
                                <p>Hover</p>
                                <p>Disable</p>
                            </div>
                            <div className={style.iconsContainer}>
                                <Icons icons="twitter" type="active" href="#"/>
                                <Icons icons="twitter" href="#"/>
                                <Icons icons="twitter" type="disabled"/>
                            </div>
                        </div>

                        <div className={style.icons}>
                            <div className={style.status}>
                                <p>All icons</p>
                            </div>
                            <div className={style.iconsContainerFull}>
                                <Icons icons="twitter" href="#"/>
                                <Icons icons="facebook" href="#"/>
                                <Icons icons="linkedin" href="#"/>
                                <Icons icons="instagram" href="#"/>
                                <Icons icons="pinterest" href="#"/>
                                <Icons icons="mail" styled={{paddingTop: '5px'}} href="#"/>
                                <Icons icons="phone" styled={{paddingTop: '5px'}} href="#"/>
                                <Icons icons="cellphone" href="#"/>
                            </div>
                        </div>

                        <div className={style.icons}>
                            <p>Secondary color</p>
                            <div className={style.statusButton}>
                                <p>Active</p>
                                <p>Hover</p>
                                <p>Disable</p>
                            </div>
                            <div className={style.iconsContainerButtons}>
                                <Icons icons="signout" type="active" styled={{width:"24px"}} href="#"/>
                                <Icons icons="signout"  styled={{width:"24px"}} href="#"/>
                                <Icons icons="signout" type="disabled" href="#" styled={{width:"24px"}}/>
                            </div>
                        </div>

                        <div className={style.icons} style={{marginBottom: '98px'}}>
                            <div className={style.statusButton}>
                                <p>All icons</p>

                            </div>

                            <div className={style.iconsContainerButtons}>
                                <Icons icons="signout" href="#" styled={{width:"24px"}} />
                                <Icons icons="linemenu" href="#"styled={{width:"24px"}}/>
                                <Icons icons="" styled={{width:"24px"}}/>
                            </div>
                        </div>
                    </Block>
                    <Block>
                        <h2>Buttons</h2>

                        <Buttons/>
                    </Block>

                    <Block>
                        <h2>Fields</h2>

                        <Fields/>
                    </Block>

                    <p className={style.fotname}>© abz.agency specially for the test task</p>
                </Container>
            </div>
        )
    }
}

export default StyleGuide;
