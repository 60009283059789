import React, { lazy, Suspense } from "react";
import style from './style.module.scss'
import Container from '@material-ui/core/Container';
import i18n from '../../../../i18n';

import { ReactSVG } from 'react-svg'
import man from '../../../../assets/img/man-mobile.svg'
import Link from '../Link'

const Lets = (props) => {
    const {lng} = props

    return (
        <div className={style.lets}>
            <div id="about" style={{transform: 'translateY(-44px)'}}></div>

            <Container maxWidth="lg" className={style.letsContainer}>
                <h2>{ i18n.t("Let's get acquainted", { lng }) }</h2>
                <div className={style.letsContent}>
                    <ReactSVG src={man} className={style.manImg}   loading={() => <div className={style.svgLoad}></div>} alt="abz.agency manmobile"/>
                    <div className={style.about}>
                        <h3>{ i18n.t('I am cool frontend developer', { lng }) }</h3>
                        <p className="prg2">
                            { i18n.t('When real users', { lng }) }
                            <br></br>
                            <br></br>
                            { i18n.t('Last week', { lng }) }
                        </p>
                        <Link type="big" text={ i18n.t('Sign Up', { lng }) } href="/register"/>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Lets
