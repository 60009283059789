import React from "react";
import style from "../style.module.scss";
import Link from "../../Link";
import i18n from "../../../../../i18n";

const Category = (props) => {
    const { lng } = props;

    return(
        <div className={style.footerCenter_category}>
            <ul className={style.footerCenter_categoryList}>
                <li><Link type="light" text={ i18n.t('News', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Blog', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Partners', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Shop', { lng }) } href="#"/></li>
            </ul>
            <ul className={style.footerCenter_categoryList}>
                <li><Link type="light" text={ i18n.t('Overview', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Design', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Code', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Collaborate', { lng }) } href="#"/></li>
            </ul>
            <ul className={style.footerCenter_categoryList}>
                <li><Link type="light" text={ i18n.t('Tutorials', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Resources', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Guides', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Examples', { lng }) } href="#"/></li>
            </ul>
            <ul className={style.footerCenter_categoryList}>
                <li><Link type="light" text={ i18n.t('FAQ', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Terms', { lng }) } href="/terms"/></li>
                <li><Link type="light" text={ i18n.t('Conditions', { lng }) } href="#"/></li>
                <li><Link type="light" text={ i18n.t('Help', { lng }) } href="#"/></li>
            </ul>
        </div>
    )
}

export default Category
