import React from 'react';
import style from './style.module.scss'
const { detect } = require('detect-browser');


const browser = detect();
const Colors = (props) => {
    if(/Windows/g.test(browser.os) || /Android/g.test(browser.os)) {
        return(
            <div className={style.fontfamilies}>
                <div className={style.about}>
                    <h4>{props.color}</h4>
                    <div className={style.aboutFont}>
                        <div className={style.header}>
                            <p>RGB:</p>
                            <p>HEX:</p>
                        </div>
                        <div className="value">
                            <p>{props.rgb}</p>
                            <p>{props.hex}</p>
                        </div>
                    </div>
                </div>
                <div className={style.example + ' ' +style.exampleW} style={props.styled}>
                    <div style={{backgroundColor: props.hex, border: props.border || 'none'}}></div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={style.fontfamilies}>
                <div className={style.about}>
                    <h4>{props.color}</h4>
                    <div className={style.aboutFont}>
                        <div className={style.header}>
                            <p>RGB:</p>
                            <p>HEX:</p>
                        </div>
                        <div className="value">
                            <p>{props.rgb}</p>
                            <p>{props.hex}</p>
                        </div>
                    </div>
                </div>
                <div className={style.example} style={props.styled}>
                    <div style={{backgroundColor: props.hex, border: props.border || 'none'}}></div>
                </div>
            </div>
        )
    }

}

export default Colors
