import React from 'react';
import style from './style.module.scss'
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const ColorButton = withStyles(theme => ({
    root: {
        color: 'white',
        backgroundColor: '#ef6c00',
        '&:hover': {
            backgroundColor: '#fc831f',
        },
        label: {

        },
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '0',
        textTransform: 'capitalize',
        width: '290px',
        height: '40px'
    },
    disabled: {
        backgroundColor: '#d7d7d7',
        color: '#8d8c8c'
    },
    outlined: {
        backgroundColor: 'white',
        color: '#ef6c00',
        border: '2px solid #ef6c00',
        '&:hover': {
            backgroundColor: 'rgba(252,131,31,0.2)',

        },
    }
}))(Button);

const Buttons = (props) => {
    return(
        <div className={style.buttons}>
            <div className={style.col1}>
                <ColorButton onClick={()=>{window.location = '#'}}>Primary</ColorButton>
                <ColorButton disabled>Disabled</ColorButton>
            </div>
            <div className={style.col2}>
                <ColorButton variant="outlined" onClick={()=>{window.location = '#'}}>Secondary</ColorButton>
            </div>
        </div>
    )
}

export default Buttons
