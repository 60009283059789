import React from 'react';

import style from "./style.module.scss";

const Linemenu = (props) => {
    const { href, type, styled} = props
    return (
        <div className={style.icon}>
            <a href={href}
               className={style.svgWrapper + ' ' + style[type] + ' ' + style.mobileClass}>
                <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" style={styled}>
                    <title>line-menu</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="cvf" className={style.svg + ' ' + style.svgDark}
                           transform="translate(-1.000000, 0.000000)" fill="#283593" fill-rule="nonzero">
                            <path
                                d="M2.9,3.85 C2.4007447,3.86349835 1.91724177,3.6745636 1.55940901,3.3261475 C1.20157626,2.9777314 0.999817556,2.49943771 1,2 C0.972540222,1.47899689 1.1605289,0.969625434 1.51986758,0.591374197 C1.87920625,0.213122961 2.37827425,-0.000723140068 2.9,1.11022302e-16 L22.05,1.11022302e-16 C23.1349951,0.0271333055 24.0003392,0.914665715 24,2 C24.0001776,2.51269524 23.7931506,3.00370229 23.4259431,3.36149423 C23.0587356,3.71928617 22.5625177,3.91349201 22.05,3.9 L2.9,3.85 Z"
                                id="Shape"></path>
                            <path
                                d="M22.05,8.57 C23.1158588,8.59632945 23.9736706,9.45414122 24,10.52 C24.0001776,11.0326952 23.7931506,11.5237023 23.4259431,11.8814942 C23.0587356,12.2392862 22.5625177,12.433492 22.05,12.42 L2.9,12.42 C2.39194706,12.4339272 1.90054037,12.2382249 1.54115774,11.8788423 C1.18177511,11.5194596 0.986072825,11.0280529 1,10.52 C0.986507989,10.0074823 1.18071383,9.51126442 1.53850577,9.1440569 C1.89629771,8.77684939 2.38730476,8.56982244 2.9,8.57 L22.05,8.57 Z"
                                id="Shape"></path>
                            <path
                                d="M22.05,17.2 C22.5607875,17.1865474 23.055465,17.3793951 23.4223722,17.7350128 C23.7892794,18.0906305 23.9974878,18.5790415 24,19.09 C24.0003392,20.1753343 23.1349951,21.0628667 22.05,21.09 L2.9,21.09 C2.37827425,21.0907231 1.87920625,20.876877 1.51986758,20.4986258 C1.1605289,20.1203746 0.972540222,19.6110031 1,19.09 C0.988861135,18.5836606 1.18579474,18.094894 1.54486108,17.7377175 C1.90392743,17.380541 2.39372639,17.1861893 2.9,17.2 L22.05,17.2 Z"
                                id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    )
}
export default Linemenu;






