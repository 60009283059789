import React from 'react';
import style from './style.module.scss'
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const ColorField = withStyles(theme => ({
    root: {
        width: '403px',
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset':{
                border: '2px solid #b7b7b7',

                '&.Mui-error fieldset': {
                    border: '2px solid #f44336',

                }
            },

            '&.Mui-focused.Mui-error fieldset': {
                border: '2px solid #f44336',

            },


        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '&:hover fieldset': {
                borderColor: '#b7b7b7',
            },
        },

        '& .MuiFormHelperText-root': {
            fontFamily: 'Source Sans Pro',
            fontSize: '12px',
            color: '#8d8c8c',
            marginTop: '7px',
            marginLeft: '16px',

            '&.Mui-error': {
                color:'#f44336'
            }
        },
        '& .MuiFormLabel-root': {
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            color: '#8d8c8c',

            '&.Mui-error': {
                color:'#f44336'
            }
        },
        '& .MuiInputBase-root': {
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            color: 'black',

            '& .MuiInputBase-input:hover':{
                cursor: 'pointer',
            },

            '& .MuiInputBase-input:focus':{
                cursor: 'text',
            }
        }
    }
}))(TextField);

const ColorSelect = withStyles(theme => ({
    root: {
        width: '354.5px',
        '&.MuiOutlinedInput-root': {


        },

        fieldset:{
            border: "2px solid #b7b7b7 !important"},
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        color: 'black',

    },
    fieldset:{
        border: "2px solid #b7b7b7 !important"}
}))(Select);

const Fields = (props) => {

    const [age, setAge] = React.useState('10');

    const handleChange = event => {
        setAge(event.target.value);
    };

    return(
        <div className={style.fields}>
            <div className={style.col1}>
                <h2>Input - Enabled</h2>
                <ColorField variant="outlined" label="Label text" helperText="Assistive text"  className={style.Input} placeholder="Input text"/>

                <h2>Select - Enabled</h2>
                <FormControl className="customselect">

                    <ColorSelect
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={age}
                        onChange={handleChange}
                        variant="outlined"
                        helperText="Assistive text"

                        style={{'.MuiInputBase-root.Mui-focused fieldset':{ border: '2px solid #b7b7b7 !important'}}}

                    >
                        <MenuItem value={10}>Selected item</MenuItem>
                        <MenuItem value={20}>Second item</MenuItem>
                        <MenuItem value={30}>Thurd item</MenuItem>

                    </ColorSelect>
                    <FormHelperText style={{
                        fontFamily: 'Source Sans Pro',
                        fontSize: '12px',
                        color: '#8d8c8c',
                        paddingLeft: '16px',
                        marginTop: '7px'
                    }}>Assistive text</FormHelperText>

                </FormControl>


            </div>
            <div className="col2">
                <h2>Input - Error - Enabled</h2>
                <ColorField variant="outlined" label="Label text" helperText="Error" error className={style.Input} placeholder="Input text"/>


            </div>
        </div>
    )
}

export default Fields
