import React, {Component} from 'react';
import style from "../style.module.scss";


class Lang extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedDE: '',
            lang: ''
        }
    }
    changeLang=(e)=>{
        window.localStorage.setItem('lng', e.target.value)
        this.props.onLanguageChanged(e.target.value)
    }
    componentDidMount() {
        this.setState({
            lang: window.localStorage.getItem('lng')
        },()=>{
            if(this.state.lang === 'de'){
                document.querySelector('#deuch').checked = true
            }else{
                document.querySelector('#engl').checked = true
            }
        })
    }


    render() {
        const labelstyle = {fontSize: '1px', position: 'absolute'}

        return(
            <div className={style.footerBottom_lang+ ' '+ 'footerBottom_langMobile'}>
                <div>
                    <label htmlFor="engl" className="visuallyhidden" style={labelstyle}>EN</label>

                    <input id="engl" value="en" onClick={this.changeLang} type="radio" name="radio" />
                    <span className="checkmark">EN</span>
                </div>
                <div>
                    <label htmlFor="deuch" className="visuallyhidden" style={labelstyle}>DE</label>
                    <input type="radio" id="deuch" onClick={this.changeLang} value="de" name="radio" />
                    <span className="checkmark">DE</span>
                </div>
            </div>
        )
    }
}

export default Lang;
