import React from 'react';
import style from './style.module.scss'
import './globlalButton.scss'
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
const { detect } = require('detect-browser');
const browser = detect();
const ColorButton = withStyles(theme => ({
    root: {
        color: 'white',
        backgroundColor: '#ef6c00',

        label: {

        },
        '&:hover': {
            backgroundColor: '#fc831f',
        },
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '0',
        textTransform: 'capitalize',
        width: '290px',
        height: '40px'
    },
    disabled: {
        backgroundColor: '#d7d7d7',
        color: '#8d8c8c'
    },
    outlined: {
        backgroundColor: 'white',
        color: '#ef6c00',
        border: '2px solid #ef6c00',
        '&:hover': {
            backgroundColor: 'rgba(252,131,31,0.2)',
        },
    }
}))(Button);

const Buttons = (props) => {
    if(/Android/g.test(browser.os) || /iOS/g.test(browser.os) ) {
        if(props.variant !== 'disabled'){
            return(
                <ColorButton {...props} variant={props.variant} onClick={props.onClick} disableRipple className={style.Checkhomer+' '+style[props.variant]}>{props.text}</ColorButton>
            )
        }else{
            return(
                <ColorButton {...props}  variant={props.variant} onClick={props.onClick} >{props.text}</ColorButton>
            )
        }
    }else{
        return(
            <ColorButton {...props}  variant={props.variant} onClick={props.onClick} >{props.text}</ColorButton>
        )
    }
    if(/Android/g.test(browser.os) || /iOS/g.test(browser.os) && props.variant === 'disabled') {
        return(
            <ColorButton {...props} variant={props.variant} onClick={props.onClick} disableRipple className={style[props.variant]}>{props.text}</ColorButton>
        )
    }else{
        return(
            <ColorButton {...props}  variant={props.variant} onClick={props.onClick} >{props.text}</ColorButton>
        )
    }


}

export default Buttons
