import React, {Component} from 'react';
import i18n from "../../i18n";
import './style.scss'
import {Route} from "react-router-dom";
import Header from "./Components/Header";
import HomePage from './HomePage'
import Footer from "./Components/Footer";
import Loadable from "react-loadable";

const { detect } = require('detect-browser');
const browser = detect();

function MyLoadingComponent({ error }) {
    if (error) {
        return <div>Error!</div>;
    } else {
        return <div></div>;
    }
}
const Register = Loadable({
    loader: () => import('./RegisterPage'),
    loading: MyLoadingComponent,
    delay: 0,
});
const TermsPage = Loadable({
    loader: () => import('./Terms'),
    loading: MyLoadingComponent,
    delay: 0,
});
const Page404 = Loadable({
    loader: () => import('./404'),
    loading: MyLoadingComponent,
    delay: 0,
});

class GeneralPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lng: ''
        }
    }
    componentDidMount() {
        if (/Windows/g.test(browser.os) || /Android/g.test(browser.os) || /iOS/g.test(browser.os)){
            import('./styleWindows.scss');
        }
        if (/Android/g.test(browser.os) || /iOS/g.test(browser.os)) {
            import('./styleMobile.scss')
        }

        this.setState({
            lng: window.localStorage.getItem('lng') || 'en'
        },()=>[
            i18n.on('languageChanged', this.onLanguageChanged)
        ])
    }

    componentWillUnmount() {
        i18n.off('languageChanged', this.onLanguageChanged)
    }

    onLanguageChanged=(lng)=> {
        this.setState({
            lng: lng
        },()=>{
            window.localStorage.setItem('lng', this.state.lng);
        })
    }

    render() {
        const { lng } = this.state
        return (
            <div className="generalpage">
                <Header lng={lng} browser={browser}/>
                    <div className="main">
                        <Route path="/" exact>
                            <HomePage lng={lng} browser={browser}/>
                        </Route>
                        <Route path="/register" exact>
                            <Register lng={lng} browser={browser}/>
                        </Route>
                        <Route path="/terms" exact>
                            <TermsPage lng={lng}/>
                        </Route>
                        <Route path="/404" exact>
                            <Page404 lng={lng}/>
                        </Route>
                    </div>
                    <Footer lng={lng} onLanguageChanged={this.onLanguageChanged} browser={browser}/>
            </div>
        );
    }
}

export default GeneralPage;
