import React from 'react';
import style from './style.module.scss'


const Links = (props) => {
    let { type, href, onClick, text} = props
    if (type === 'light') {
        return (
            <a href={href} onClick={onClick}
               className={style.light + ' ' + style.mobileClassLinks}>{text}</a>
        )
    } else if (type === 'big') {
        return (
            <a href={href} onClick={onClick}
               className={style.big + ' ' + style.mobileClassLinks}>{text}</a>
        )
    } else if (type === 'active') {
        return (
            <a href={href} onClick={onClick}
               className={style.active + ' ' + style.mobileClassLinks}>{text}</a>
        )
    } else if (type === 'dark') {
        return (
            <a href={href} onClick={onClick}
               className={style.dark + ' ' + style.mobileClassLinks}>{text}</a>
        )
    }
}
export default Links
