import React from 'react';
import style from './style.module.scss'

const { detect } = require('detect-browser');

const browser = detect();

const FontFamilies = (props) => {

    if(/Windows/g.test(browser.os) || /Android/g.test(browser.os)) {
        return(
            <div className={style.fontfamilies}>
                <div className={style.about}>
                    <h4>Font</h4>
                    <div className={style.aboutFont}>
                        <div className={style.header}>
                            <p>Font family:</p>
                            <p>Styles:</p>
                        </div>
                        <div className="value">
                            <p>{props.family}</p>
                            {props.styles.map((style,i) => (
                                <p key={i}>{style}</p>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={style.example}>
                    <div className={style.big}>
                        <p style={{fontFamily: props.familyStyle, fontWeight: props.fontWeight, paddingTop: props.paddingW || 0}}>Aa</p>
                    </div>
                    <div className={style.small}>
                        <p style={{fontFamily: props.familyStyle, fontWeight: props.fontWeight, paddingTop: props.paddingWs || 0}}>{props.family}</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={style.fontfamilies}>
                <div className={style.about}>
                    <h4>Font</h4>
                    <div className={style.aboutFont}>
                        <div className={style.header}>
                            <p>Font family:</p>
                            <p>Styles:</p>
                        </div>
                        <div className="value">
                            <p>{props.family}</p>
                            {props.styles.map((style,i) => (
                                <p key={i}>{style}</p>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={style.example}>
                    <div className={style.big}>
                        <p style={{fontFamily: props.familyStyle, fontWeight: props.fontWeight}}>Aa</p>
                    </div>
                    <div className={style.small}>
                        <p style={{fontFamily: props.familyStyle, fontWeight: props.fontWeight}}>{props.family}</p>
                    </div>
                </div>
            </div>
        )
    }

}

export default FontFamilies
