
import React from 'react';
import Container from '@material-ui/core/Container';
import style from "./style.module.scss";
import Buttons from '../Buttons/index';
import i18n from '../../../../i18n';



const Banner = (props) => {
    const { lng } = props;
    return (
        <div id="bannerWrap" className={style.bannerWrap} alt="abz.agency banner">
            <Container maxWidth="lg" className={style.bannerContainer}>
                <div className={style.bannerInfo}>
                    <h1>{ i18n.t('Test assignment', { lng }) }</h1>
                    <p>{ i18n.t('We kindly', { lng }) }</p>
                    <p className={style.shortP}>{ i18n.t('We kindly_SHORT', { lng }) }</p>
                    <Buttons text={ i18n.t('Sign Up', { lng }) } style={style.button} href="/register"/>
                </div>
            </Container>
        </div>
    );
}

export default Banner;


