import React from 'react';
import style from './style.module.scss'
import Links from '../Links'

const Menu = (props) => {
    return(
        <div className={style.menu}>
            <Links text="First item" href="#" type="dark"/>
            <Links text="Active item" href="#" type="active"/>
            <Links text="Third item" href="#" type="dark"/>
            <Links text="Hovered item" href="#" type="dark"/>
            <Links text="Fifth item"  href="#" type="dark"/>
        </div>
    )

}

export default Menu
