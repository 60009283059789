import React from "react";
import style from "../style.module.scss";
import ReactPlaceholder from "react-placeholder";
import {ReactSVG} from "react-svg";
import userPhoto from "../../../../../assets/img/cover-icon-user.svg";
import Divider from "@material-ui/core/Divider";
import Link from "../../Link";
import Drawer from '@material-ui/core/Drawer';
import i18n from "../../../../../i18n";

const DrawerC = (props) => {
    const { lng, state } = props;

    let href = '';
    if(window.location.pathname !== '/'|| window.location.pathname !== '/'){
        href = '/'
    }
    return(
        <Drawer open={state.open} onClose={props.toggleDrawer(false)}>
            <div
                className={style.list}
                role="presentation">
                <div className={style.usermobile}>
                    <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={state.load} style={{ width: 70, height: 70}}>
                        <div className={style.usermobile_img} style={{overflow: "hidden", display: 'flex', justifyContent: 'center', aligntItems: 'center'}}>
                            <img src={state.img} alt={state.name} height="100%" loading="lazy"></img>
                        </div>
                    </ReactPlaceholder>
                    <div className={style.usermobile_info}>
                        <props.CustomTool title={state.name} disableHoverListener={!state.openToolName} disableFocusListener={!state.openToolName} disableTouchListener={state.openToolName} style={{display: state.displayToolName}}>
                            <p id="nameCheckMobile" style={{cursor: state.cursorName}}>
                                {state.name}</p>
                        </props.CustomTool>
                        <props.CustomTool title={state.email} disableHoverListener={!state.openTool} disableFocusListener={!state.openTool}  disableTouchListener={state.openTool} style={{display: state.displayTool}}>
                            <p id="emailCheckMobile" style={{cursor: state.cursor}}>{state.email}</p>
                        </props.CustomTool>
                    </div>
                </div>
                <Divider/>
                <div className={style.menumobile}>
                    <ul className={style.menumobile_list}
                        onClick={props.toggleDrawer(false)}
                        onKeyDown={props.toggleDrawer(false)}>
                        <li><Link type="dark" text={ i18n.t('About me', { lng }) } href={href+"#about"}/></li>
                        <li><Link type="dark" text={ i18n.t('Relationships', { lng }) } href={href+"#relationships"}/></li>
                        <li><Link type="dark" text={ i18n.t('Users', { lng }) } href={href+"#users"}/></li>
                        <li><Link type="dark" text={ i18n.t('Sign Up', { lng }) } href="/register"/></li>
                        <li><Link type="dark" text={ i18n.t('Terms and Conditions', { lng }) } href="/terms"/></li>
                    </ul>
                </div>
            </div>
        </Drawer>
    )
}

export default DrawerC
