import React from 'react';
import style from './style.module.scss'
import logo from '../../../../assets/img/logo.svg'

const Header = () => {
    return(
        <div className={style.header}>
            <div className={style.headerContent}>
                <img src={logo} alt="abz.agency logo"/>
                <h3>STYLE GUIDE</h3>
            </div>
            <hr style={{color: '#cccccc',
                backgroundColor: '#cccccc'}}/>
        </div>
    )
}

export default Header
