import React from "react";
import style from "../style.module.scss";
import Facebook from "../../Icons/facebook";
import Linkedin from "../../Icons/linkedin";
import Instagram from "../../Icons/instagram";
import Twitter from "../../Icons/twitter";
import Pinterest from "../../Icons/pinterest";

const Social = () => {

    return(
        <div className={style.footerBottom_icons+' '+' footerBottom_icons'}>
            <Facebook href="#" className={style.footerBottom_icon}/>
            <Linkedin href="#" className={style.footerBottom_icon}/>
            <Instagram href="#" className={style.footerBottom_icon}/>
            <Twitter href="#" className={style.footerBottom_icon}/>
            <Pinterest href="#" className={style.footerBottom_icon}/>
        </div>
    )

}

export default Social
