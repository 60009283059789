import React from "react";
import style from "./style.module.scss";

const Pinterest = (props) => {
    const { href, type, styled } = props

    return(
        <div className={style.icon} >
            <a href={href}  className={style.svgWrapper +' '+ style[type]+' '+style.mobileClass+' socIcons'} style={props.styleIcon}>
                <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styled}>
                    <title>pinterest</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="cvf" className={style.svg} fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M19.48,0 L6.5,0 C2.90919977,0.0164928064 0.00546111025,2.92916605 0,6.52 L0,19.52 C0.0273388392,23.0952131 2.92471965,25.9836789 6.5,26 L19.5,26 C23.0908002,25.9835072 25.9945389,23.0708339 26,19.48 L26,6.48 C25.9725685,2.89701754 23.0630834,0.00538210981 19.48,0 Z M14.3,16.61 C13.3922361,16.6221996 12.5348986,16.1935309 12,15.46 C12,15.46 11.44,17.6 11.32,18.01 C10.9217186,19.1231301 10.3329528,20.1585458 9.58,21.07 C9.56067003,21.1445584 9.48455844,21.18933 9.41,21.17 C9.33544156,21.15067 9.29067003,21.0745584 9.31,21 C9.11363262,19.8248132 9.11363262,18.6251868 9.31,17.45 C9.49,16.68 10.55,12.37 10.55,12.37 C10.3416799,11.9116819 10.2358855,11.413424 10.24,10.91 C10.24,9.52 11.06,8.49 12.09,8.49 C12.45579,8.47941767 12.8083672,8.62713527 13.0573736,8.89529597 C13.3063799,9.16345668 13.4276118,9.52599494 13.39,9.89 C13.2081478,11.0159435 12.9270523,12.1235937 12.55,13.2 C12.4342718,13.6566014 12.54755,14.1409651 12.8537706,14.4988852 C13.1599911,14.8568053 13.62099,15.0436746 14.09,15 C15.91,15 17.15,12.73 17.15,10.05 C17.15,8.05 15.72,6.48 13.15,6.48 C11.9200004,6.40863989 10.714718,6.84585185 9.81652758,7.68920538 C8.91833715,8.53255892 8.40616379,9.70794755 8.4,10.94 C8.36538152,11.6034004 8.57930964,12.2558812 9,12.77 C9.15667616,12.8932016 9.21364218,13.1047896 9.14,13.29 C9.14,13.45 9,13.87 8.94,14.03 C8.92057673,14.133319 8.85308778,14.2212013 8.75828084,14.2666296 C8.6634739,14.3120579 8.55269494,14.3095957 8.46,14.26 C7.11190535,13.6058248 6.31384622,12.1812892 6.46,10.69 C6.46,8.05 8.77,4.87 13.33,4.87 C17.01,4.87 19.42,7.45 19.42,10.21 C19.48,13.89 17.4,16.61 14.3,16.61 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    )


}

export default Pinterest
