import React from "react";
import style from "./style.module.scss";
const Linkedin = (props) => {
    const { href, type, styled} = props
    return(
        <div className={style.icon} >
            <a href={href}  className={style.svgWrapper+' '+ style[type]+' mobileClass'+' socIcons'} style={props.styleIcon}>
                <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styled}>
                    <title>linkedin</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="cvf" className={style.svg} fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M19.46,7.28306304e-16 L6.52,7.28306304e-16 C2.92138684,0.00550668626 0.00550668626,2.92138684 0,6.52 L0,19.52 C0.0274315433,23.1029825 2.93691658,25.9946179 6.52,26 L19.52,26 C23.1107807,25.9725091 26.0056043,23.0508815 26,19.46 L26,6.52 C25.9780675,2.92042909 23.0596212,0.0109076877 19.46,7.28306304e-16 Z M8.66,21 L5,21 L5,10.31 L8.66,10.31 L8.66,21 Z M6.78,8.66 C5.73065898,8.66 4.88,7.80934102 4.88,6.76 C4.88,5.71065898 5.73065898,4.86 6.78,4.86 C7.82934102,4.86 8.68,5.71065898 8.68,6.76 C8.66391582,7.80260607 7.82260607,8.64391582 6.78,8.66 Z M21.05,21 L17.94,21 L17.94,15.84 C17.94,14.58 17.77,12.97 16.06,12.97 C14.35,12.97 14.06,14.33 14.06,15.75 L14.06,21 L10.93,21 L10.93,10.31 L13.81,10.31 L13.81,11.75 L13.9,11.75 C14.6007249,10.7200391 15.8111802,10.1590027 17.05,10.29 C20.45,10.29 21.05,12.29 21.05,15.15 L21.05,21 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    )
}

export default Linkedin
