import React, {Component} from 'react';
import style from './style.module.scss'

class Links extends Component {
    render() {
        if(this.props.type === 'light'){
            return(
                <div className={style.links} >
                    <a href={this.props.href} className={style.light} >{this.props.text}</a>
                </div>
            )
        } else if(this.props.type === 'big'){
            return(
                <div className={style.links}>
                    <a href={this.props.href} className={style.big}>{this.props.text}</a>
                </div>
            )
        } else if (this.props.type === 'active') {
            return(
                <div className={style.links}>
                    <a href={this.props.href} className={style.active}>{this.props.text}</a>
                </div>
            )
        }else if (this.props.type === 'dark') {
            return(
                <div className={style.links}>
                    <a href={this.props.href} className={style.dark}>{this.props.text}</a>
                </div>
            )
        } else {
            return(
                <div className={style.linksDemo}>
                    <a href={this.props.href} className={style.dark}>{this.props.text}</a>
                </div>
            )
        }
    }
}

export default Links;
