import Container  from '@material-ui/core/Container';
import style from './style.module.scss'
import logo from '../../../../assets/img/logo.svg';
import Link from '../Link';
import Signout from '../Icons/signout'
import Linemenu from "../Icons/linemenu";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UserAPI from "../../UserAPI";
import React, {Component} from 'react';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Tooltip from "@material-ui/core/Tooltip";
import {ReactSVG} from "react-svg";
import userPhotoS from "../../../../assets/img/cover-icon-userSmall.svg";
import HeaderUser from "./HeaderUser";
import i18n from '../../../../i18n';
import DrawerC from "./Drawer";


const ColorButton = withStyles(theme => ({
    root: {
        height: '48px',
        minWidth: '48px',
        color: 'none',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
}))(Button);
let pos = '';
let htmlS = document.querySelector('html');

const CustomTool = withStyles(theme => ({
    tooltip: {
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 13,

        padding: '5px 10px',
        top: '10px',
        imgUrl: ''
    },
}))(Tooltip);


class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            load: false,
            name: '',
            email: '',
            img: '',
            cursor: 'default',
            cursorName: 'default',
            displayToolName: 'none',
            displayTool: 'none',
            openTool: false,
            openToolName: false,
            openHandName: false,
            openHandEmail: false,
        }
    }

    toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            open: open
        },()=>{
            if (open){
                pos = window.pageYOffset
                htmlS.style.position = 'fixed'
                htmlS.style.top = "-"+ pos + 'px'
                htmlS.style.width = '100%'
                htmlS.style.height = '100vh'
            } else {
                htmlS.style.position = 'static'
                htmlS.style.width = '100%'
                window.scrollTo(0,pos);
            }
        })
    };

    componentDidMount() {
        UserAPI.getUser(541).then((res)=>res.json()).then((data)=>{
            this.setState({
                load: true,
                name: data.user.name,
                email: data.user.email,
            },()=>{
                document.querySelector('.main').addEventListener('touchstart',(e)=>{
                    if(e.target.innerHTML !== this.state.email && e.target.innerHTML !== this.state.name){
                        this.setState({
                            openHandEmail: false,
                            openHandName: false
                        })
                    }},{passive: true})

                let els = document.querySelector('#emailCheck')
                if(els.scrollWidth > els.clientWidth) {
                    this.setState({
                        openTool: true,
                        cursor: 'pointer',
                        displayTool: 'block'
                    })
                }
                let elsw = document.querySelector('#nameCheck')
                if(elsw.scrollWidth > elsw.clientWidth) {
                    this.setState({
                        openToolName: true,
                        cursorName: 'pointer',
                        displayToolName: 'block'
                    })
                }
                let elsM = document.querySelector('#emailCheckMobile')
                if(elsM && elsM.scrollWidth > elsM.clientWidth) {
                    this.setState({
                        openTool: true,
                        cursor: 'pointer',
                        displayTool: 'block'
                    })
                }
                let elsMw = document.querySelector('#nameCheckMobile')
                if(elsMw && elsMw.scrollWidth > elsMw.clientWidth) {
                    this.setState({
                        openToolName: true,
                        cursorName: 'pointer',
                        displayToolName: 'block'
                    })
                }
            })
            let img = new Image()
            img.src = data.user.photo;
            img.onload = () => {
                this.setState({
                    img: data.user.photo,
                }, )
            }
            img.onerror = () => {
                this.setState({
                    img: userPhotoS,
                }, )
            }

        })
    }

    handleTooltipOpenName = ()=>{
        if(this.state.displayToolName !== 'none'){
            this.setState({
                openHandName: !this.state.openHandName
            })
        }
    }
    handleTooltipOpenEmail = ()=>{
        if(this.state.displayTool !== 'none'){
            this.setState({
                openHandEmail: !this.state.openHandEmail
            })
        }
    }

    render() {
        const { lng, browser } = this.props;
        const { img, name, load} = this.state;

        return (
            <div className={style.fixed}>
                <Container maxWidth="lg" className={style.headerContainer}>
                    <header className={style.headerWrapper}>
                        <a href="/" style={{height: '24px'}}>
                            <img src={logo} alt="abz.agency logo" className={style.logo}/>
                        </a>
                        <div className={style.headerNavigation}>
                            <ul className={style.headerMenu}>
                                <li><Link type="dark" text={ i18n.t('About me', { lng }) } href="#about"/></li>
                                <li><Link type="dark" text={ i18n.t('Relationships', { lng }) } href="#relationships"/></li>
                                <li><Link type="dark" text={ i18n.t('Users', { lng }) } href="#users"/></li>
                                <li><Link type="dark" text={ i18n.t('Sign Up', { lng }) } href="/register"/></li>
                            </ul>
                            <div className={style.headerUser}>
                                <ReactPlaceholder type='text' ready={load} showLoadingAnimation={true} rows={2} color='#E0E0E0' style={{width: '108px', paddingRight: '15px', height: '30px', padding: '0px 15pxpx 0px 0px'}}>
                                    <HeaderUser browser={browser} state={this.state} handleTooltipOpenEmail={this.handleTooltipOpenEmail} handleTooltipOpenName={this.handleTooltipOpenName} CustomTool={CustomTool}/>
                                </ReactPlaceholder>
                                <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhotoS} style={{background: '#eeeeee',width: '40px', height: '40px', borderRadius: '50px', display: 'flex', justifyContent: 'center', marginRight: '15px'}}/>} showLoadingAnimation={true} type='round' ready={load} style={{ width: 40, height: 40}}>
                                    <div className={style.headerUser_img} style={{overflow: "hidden", display: 'flex', justifyContent: 'center', aligntItems: 'center'}}>
                                        <img src={img} alt={name}  loading="lazy" height='100%'></img>
                                    </div>
                                </ReactPlaceholder>
                                <Signout href="#" />
                            </div>
                        </div>
                            <DrawerC lng={lng} state={this.state} toggleDrawer={this.toggleDrawer} CustomTool={CustomTool}/>
                        <ColorButton onClick={this.toggleDrawer(true)} className={style.menuButton} size="small" disableRipple>
                            <Linemenu  onClick={this.toggleDrawer(true)}/>
                        </ColorButton>
                    </header>
                </Container>
            </div>
        )
    }
}

export default Header;
