import React from "react";
import style from "./style.module.scss";

const Cellphone = (props) => {
    const { href, type, styled} = props

    return(
        <div className={style.icons}>
            <a href={href}  className={style.svgWrapper+' '+ style[type]+' '+style.mobileClass+' '+style.socIcons}>
                <svg width="16px" height="28px" viewBox="0 0 16 28" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styled}>
                    <title>cellphone</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="cvf" className={style.svg} transform="translate(-1.000000, -1.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M14.54,28.12 C15.1983329,28.1338691 15.8336713,27.8777603 16.2983409,27.4112018 C16.7630104,26.9446434 17.0165397,26.3082712 17,25.65 L17,3.47 C17.0165397,2.81172879 16.7630104,2.17535661 16.2983409,1.70879818 C15.8336713,1.24223974 15.1983329,0.986130854 14.54,1 L3.46,1 C2.80166711,0.986130854 2.16632868,1.24223974 1.70165914,1.70879818 C1.23698961,2.17535661 0.983460258,2.81172879 1,3.47 L1,25.65 C0.983460258,26.3082712 1.23698961,26.9446434 1.70165914,27.4112018 C2.16632868,27.8777603 2.80166711,28.1338691 3.46,28.12 L14.54,28.12 Z M9,27.09 C8.32068976,27.09 7.77,26.5393102 7.77,25.86 C7.77,25.1806898 8.32068976,24.63 9,24.63 C9.67931024,24.63 10.23,25.1806898 10.23,25.86 C10.23,26.5393102 9.67931024,27.09 9,27.09 Z M5.92,2.5 C5.91994966,2.42662478 5.94976459,2.35638915 6.00258333,2.30545679 C6.05540208,2.25452443 6.12667511,2.22728238 6.2,2.23 L11.8,2.23 C11.9508172,2.22990379 12.0746137,2.34927899 12.08,2.5 L12.08,2.58 C12.0800503,2.65337522 12.0502354,2.72361085 11.9974167,2.77454321 C11.9445979,2.82547557 11.8733249,2.85271762 11.8,2.85 L6.2,2.85 C6.0491828,2.85009621 5.9253863,2.73072101 5.92,2.58 L5.92,2.5 Z M2.23,4.08 L15.77,4.08 L15.77,23.77 L2.23,23.77 L2.23,4.08 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    )
}

export default Cellphone
