import React from "react";
import GeneralPage from './pages/generalpage'
import StyleGuide from './pages/styleguide'

import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

function App() {
  return (
    <div className="App">
        <Router>
            <Switch>
                <Route path="/task2prog/styleguide">
                    <StyleGuide/>
                </Route>
                <Route path="/">
                    <GeneralPage/>
                </Route>
            </Switch>
        </Router>
    </div>
  );
}

export default App;
