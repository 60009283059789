import React from "react";
import Banner from "../Components/Banner";
import Lets from "../Components/Lets";

import Loadable from "react-loadable";
function MyLoadingComponent({ error }) {
    if (error) {
        return <div>Error!</div>;
    } else {
        return <div></div>;
    }
}
const LoadableAnotherComponent = Loadable({
    loader: () => import('./AnotherBlocks'),
    loading: MyLoadingComponent,
    delay: 200,
});

const HomePage = (props) => {
    document.title = "Home page";
    const { browser, lng } = props
    return (
        <div className="homepage" >
            <div className="main" style={{marginTop: '64px'}}>
                <Banner lng={lng}/>
                <Lets lng={lng}/>
                <LoadableAnotherComponent browser={browser} lng={lng}/>
            </div>
        </div>
    );
}

export default HomePage;
