
const UserAPI = {
     getToken: () => {
        return fetch('https://frontend-test-assignment-api.abz.agency/api/v1/token')
    },
    getUser: (id) => {
        return fetch(`https://frontend-test-assignment-api.abz.agency/api/v1/users/${1}`)
    },
    getUsers: (page, count) => {
        return fetch(`https://frontend-test-assignment-api.abz.agency/api/v1/users?page=${page}&count=${count}`)
    },
    postUser: (data) => {
        return fetch('https://frontend-test-assignment-api.abz.agency/api/v1/users', {
            method: 'POST',
            body: data.formData,
            headers: {
                'Token': data.token,
            },
        })
    },
    getPositions: () => {
        return fetch('https://frontend-test-assignment-api.abz.agency/api/v1/positions')
    }
}

export default UserAPI;
