import React from "react";
import style from "./style.module.scss";

const Mail = (props) => {
    const { href, type, styled, className} = props
    return(
        <div className={style.icon + ' '+ className}>
            <a href={href}  className={style.svgWrapper+' '+ style[type]+' '+style.mobileClass} style={props.styleIcon}>
                <svg width="24px" height="18px" viewBox="0 0 24 18" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styled}>
                    <title>mail</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="cvf" className={style.svg+' '+' svgi'} fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M12,12 L9,9.4 L0.54,16.68 C0.866201973,16.9751046 1.29012116,17.1389725 1.73,17.14 L22.27,17.14 C22.7073374,17.141329 23.1289494,16.9769718 23.45,16.68 L15,9.4 L12,12 Z" id="Shape"></path>
                            <path d="M23.46,0.46 C23.133798,0.16489541 22.7098788,0.0010274873 22.27,2.628411e-16 L1.73,2.628411e-16 C1.29071508,-0.00167652558 0.867834678,0.166758888 0.55,0.47 L12,10.29 L23.46,0.46 Z" id="Shape"></path>
                            <polygon id="Shape" points="0 1.5 0 15.75 8.29 8.71"></polygon>
                            <polygon id="Shape" points="15.71 8.71 24 15.75 24 1.5"></polygon>
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    )
}

export default Mail
