import React from "react";
import style from "../style.module.scss";

const HeaderUser = (props) => {
    const {browser} = props
    if (/Android/g.test(browser.os) || /iOS/g.test(browser.os)) {
        return(
            <div className={style.headerUser_info}>
                <props.CustomTool title={props.state.name} open={props.state.openHandName} disableHoverListener={true} disableFocusListener={true} disableTouchListener={false} >
                    <p id="nameCheck" onClick={props.handleTooltipOpenName} className={`prg3 ${style.headerUser_username} ${style.username}`} >
                        {props.state.name}</p>
                </props.CustomTool>
                <props.CustomTool title={props.state.email} open={props.state.openHandEmail} disableHoverListener={true} disableFocusListener={true} disableTouchListener={false} >
                    <p id="emailCheck" onClick={props.handleTooltipOpenEmail} style={{lineHeight: '12px'}}  className={style.headerUser_useremail + ' '+ style.useremail}>{props.state.email}</p>
                </props.CustomTool>
            </div>
        )
    }else{
        return(
            <div className={style.headerUser_info}>
                <props.CustomTool title={props.state.name} disableHoverListener={!props.state.openToolName} disableFocusListener={!props.state.openToolName} disableTouchListener={props.state.openToolName} >
                    <p id="nameCheck" style={{cursor: props.state.cursorName}} className={`prg3 ${style.headerUser_username} ${style.username}`} >
                        {props.state.name}</p>
                </props.CustomTool>
                <props.CustomTool title={props.state.email} disableHoverListener={!props.state.openTool} disableFocusListener={!props.state.openTool} disableTouchListener={props.state.openTool} >
                    <p id="emailCheck" style={{cursor: props.state.cursor}}   className={style.headerUser_useremail + ' '+ style.useremail}>{props.state.email}</p>
                </props.CustomTool>
            </div>
        )
    }
}

export default HeaderUser
