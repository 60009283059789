import React from 'react';
import style from "./style.module.scss";

const Twitter = (props) => {
    const { href, type, styled } = props
    return(
        <div className={style.icon} >
            <a href={href}  className={style.svgWrapper+' '+ style[type]+' '+style.mobileClass+' socIcons'} style={props.styleIcon}>
                <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styled}>
                    <title>twitter</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="cvf" className={style.svg} fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M19.46,7.28306304e-16 L6.52,7.28306304e-16 C2.92138684,0.00550668626 0.00550668626,2.92138684 0,6.52 L0,19.52 C0.0274315433,23.1029825 2.93691658,25.9946179 6.52,26 L19.52,26 C23.1107807,25.9725091 26.0056043,23.0508815 26,19.46 L26,6.52 C25.9780675,2.92042909 23.0596212,0.0109076877 19.46,7.28306304e-16 Z M19.46,9.73 L19.46,10.16 C19.4602817,12.6562666 18.4591612,15.048311 16.680925,16.8002308 C14.9026889,18.5521506 12.4959849,19.5174976 10,19.48 C8.19000448,19.4867595 6.41622306,18.9730224 4.89,18 C5.15,18 5.4,18 5.67,18 C7.16223419,18.0132772 8.61479321,17.5196892 9.79,16.6 C8.37561454,16.5831002 7.12725167,15.6719157 6.68,14.33 C6.88427783,14.3697436 7.09189196,14.3898353 7.3,14.39 C7.60084102,14.3921681 7.90047618,14.3517678 8.19,14.27 C6.66427306,13.9526392 5.57050009,12.6083839 5.57,11.05 C6.0217923,11.3070617 6.53033511,11.4479418 7.05,11.46 C5.59406692,10.5233733 5.13165333,8.60765978 6,7.11 C7.7046071,9.15917995 10.1880503,10.4027143 12.85,10.54 C12.7856439,10.2953301 12.7553582,10.0429497 12.76,9.79 C12.7652348,8.91217787 13.1210623,8.07285034 13.7483257,7.45873325 C14.375589,6.84461615 15.2222639,6.50664339 16.1,6.52 C17.01496,6.51037738 17.8949546,6.87103093 18.54,7.52 C19.2825303,7.37679112 19.9936288,7.1025103 20.64,6.71 C20.3847923,7.47073445 19.86727,8.11586494 19.18,8.53 C19.8393074,8.44842923 20.4829326,8.26983166 21.09,8 C20.6493718,8.67188324 20.0902844,9.25807788 19.44,9.73 L19.46,9.73 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    )
}
export default Twitter;






