import React from 'react';
import style from './style.module.scss'
const { detect } = require('detect-browser');


const browser = detect();
console.log()
const Typography = (props) => {
    if(/Windows/g.test(browser.os) || /Android/g.test(browser.os)){
        return(
            <div className={style.fontfamilies}>
                <div className={style.about}>
                    <h4>{props.heading} <span>{props.resize}</span></h4>
                    <div className={style.aboutFont}>
                        <div className={style.header}>
                            <p>Font family:</p>
                            <p>Style:</p>
                            <p>Size:</p>
                            <p>Lineheight:</p>
                        </div>
                        <div className="value">
                            <p>{props.family}</p>
                            <p>{props.styleW}</p>
                            <p>{props.size}</p>
                            <p>{props.lineheight}</p>
                        </div>
                    </div>
                </div>
                <div className={style.example + ' '+ style.exampleW} style={props.styledW || props.styled}>
                    <p style={{fontSize: `${props.size}px`, lineHeight: `${props.lineheight}px` || 0, paddingTop: props.paddingW || props.padding, fontWeight: props.weight, fontFamily: props.familyStyle}}>{props.text || "The quick brown fox jumps over the lazy dog."}</p>
                </div>
            </div>
        )
    } else {
        return(
            <div className={style.fontfamilies}>
                <div className={style.about}>
                    <h4>{props.heading} <span>{props.resize}</span></h4>
                    <div className={style.aboutFont}>
                        <div className={style.header}>
                            <p>Font family:</p>
                            <p>Style:</p>
                            <p>Size:</p>
                            <p>Lineheight:</p>
                        </div>
                        <div className="value">
                            <p>{props.family}</p>
                            <p>{props.styleW}</p>
                            <p>{props.size}</p>
                            <p>{props.lineheight}</p>
                        </div>
                    </div>
                </div>
                <div className={style.example} style={props.styled}>
                    <p style={{fontSize: `${props.size}px`, lineHeight: `${props.lineheight}px` || 0, paddingTop: props.padding, fontWeight: props.weight, fontFamily: props.familyStyle}}>{props.text || "The quick brown fox jumps over the lazy dog."}</p>
                </div>
            </div>
        )
    }

}

export default Typography
