import React from "react";
import style from "../style.module.scss";
import Mail from "../../Icons/mail";
import Phone from "../../Icons/phone";
import Cellphone from "../../Icons/cellphone";

const Contacts = (props) => {
    const { browser } = props
    if(/Android/g.test(browser.os) || /iOS/g.test(browser.os)) {
        if(/safari/g.test(browser.name) || /Safari/g.test(browser.name)) {
            return(
                <div className={style.footerCenter_contacts}>
                    <div onMouseEnter={props.handleChange} onMouseLeave={props.handleChangeOff}>
                        <Mail fill="white" className="mailIconHover"/>
                        <a href="mailto:work.of.future@gmail.com" ><p style={{transform: 'translateX(4px)'}}>work.of.future@gmail.com</p></a>
                    </div>
                    <div>
                        <Phone styled={{transform: 'translateY(6px)'}} type="active"/>
                        <a href="tel:+38 (050) 789 24 98" className={style.telLink}><p style={{transform: 'translateX(7px)' }}>+38 (050) 789 24 98</p></a>
                    </div>
                    <div>
                        <Cellphone styled={{transform: 'translateY(5px) translateX(3px)'}} type="active"/>
                        <a href="tel:+38 (095) 556 08 45" className={style.telLink}><p style={{transform: 'translateX(11px)'}}>+38 (095) 556 08 45</p></a>
                    </div>
                </div>
            )
        }else{
            return(
                <div className={style.footerCenter_contacts}>
                    <div  onMouseEnter={props.handleChange} onMouseLeave={props.handleChangeOff}>
                        <Mail fill="white" className="mailIconHover"/>
                        <a  href="mailto:work.of.future@gmail.com" ><p style={{transform: 'translateX(4px)'}}>work.of.future@gmail.com</p></a>
                    </div>
                    <div>
                        <Phone styled={{transform: 'translateY(6px)'}} type="active"/>
                        <a href="tel:+38 (050) 789 24 98" className={style.telLink}><p style={{transform: 'translateX(7px)' }}>+38 (050) 789 24 98</p></a>
                    </div>
                    <div>
                        <Cellphone styled={{transform: 'translateY(5px) translateX(3px)'}} type="active"/>
                        <a href="tel:+38 (095) 556 08 45" className={style.telLink}><p style={{transform: 'translateX(11px)'}}>+38 (095) 556 08 45</p></a>
                    </div>
                </div>
            )
        }
    }else{
        if(/safari/g.test(browser.name) || /Safari/g.test(browser.name)) {
            return(
                <div className={style.footerCenter_contacts}>
                    <div className="emailLink" onMouseEnter={props.handleChange} onMouseLeave={props.handleChangeOff}>
                        <Mail fill={props.state.fill} className="mailIconHover"/>
                        <a  href="mailto:work.of.future@gmail.com" ><p style={{transform: 'translateX(4px)', transition: '0.2s', color: props.state.color}}>work.of.future@gmail.com</p></a>
                    </div>
                    <div>
                        <Phone styled={{transform: 'translateY(6px)'}} type="active"/>
                        <a href="tel:+38 (050) 789 24 98" className={style.telLink}><p style={{transform: 'translateX(7px)' }}>+38 (050) 789 24 98</p></a>
                    </div>
                    <div>
                        <Cellphone styled={{transform: 'translateY(5px) translateX(3px)'}} type="active"/>
                        <a href="tel:+38 (095) 556 08 45" className={style.telLink}><p style={{transform: 'translateX(11px)'}}>+38 (095) 556 08 45</p></a>
                    </div>
                </div>
            )
        }else{
            return(
                <div className={style.footerCenter_contacts}>
                    <div className="emailLink" onMouseEnter={props.handleChange} onMouseLeave={props.handleChangeOff}>
                        <Mail fill={props.state.fill} className="mailIconHover"/>
                        <a  href="mailto:work.of.future@gmail.com" ><p style={{transform: 'translateX(4px)', transition: '0.2s', color: props.state.color}}>work.of.future@gmail.com</p></a>
                    </div>
                    <div>
                        <Phone styled={{transform: 'translateY(6px)'}} type="active"/>
                        <a href="tel:+38 (050) 789 24 98" className={style.telLink}><p style={{transform: 'translateX(7px)' }}>+38 (050) 789 24 98</p></a>
                    </div>
                    <div>
                        <Cellphone styled={{transform: 'translateY(5px) translateX(3px)'}} type="active"/>
                        <a href="tel:+38 (095) 556 08 45" className={style.telLink}><p style={{transform: 'translateX(11px)'}}>+38 (095) 556 08 45</p></a>
                    </div>
                </div>
            )
        }
    }
}

export default Contacts
