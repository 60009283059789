import React from 'react';
import style from './style.module.scss'

const Block = (props) => {
    return(
        <div className={style.block}>
            {props.children}
            <hr style={props.hr}/>
        </div>
    )
}

export default Block
