import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import style from './style.module.scss'
import logoWhite from '../../../../assets/img/logoWhite.svg'
import Link from '../Link';
import Category from "./Category";
import Social from "./Social";
import Lang from "./Lang";
import Contacts from "./Contacts";
import i18n from '../../../../i18n';
import './mailhover.scss'
class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fill: '#FFFFFF',
            checked: false
        }
    }

    handleChange = () => {
        this.setState({
            fill: "#ef6c00",
            color: "#ef6c00"
        })
    };

    handleChangeOff = () => {
        this.setState({
            fill: "#FFFFFF",
            color: "#FFFFFF"
        })
    };

    componentDidMount() {
        let lng = this.props.lng

        if(lng === 'de'){
            this.setState({
                checked: true
            })
        }else{
            this.setState({
                checked: false
            })
        }
    }

    render() {
        const { lng, browser }  = this.props

        return (
            <div className={style.bottom}>
                <div className={style.footerWrap}>
                    <Container maxWidth="lg" className={style.footerContainer}>
                        <footer className={style.footer}>
                            <div className={style.footerHeader}>
                                <a href="/" style={{height: '24px'}}><img src={logoWhite} alt="abz.agency logo"/></a>
                                <div className={style.footerHeader_navigation}>
                                    <ul className={style.footerHeader_navigationList}>
                                        <li><Link type="light" text={ i18n.t('About me', { lng }) } href="#about"/></li>
                                        <li><Link type="light" text={ i18n.t('Relationships', { lng }) } href="#relationships"/></li>
                                        <li><Link type="light" text={ i18n.t('Users', { lng }) } href="#users"/></li>
                                        <li><Link type="light" text={ i18n.t('Sign Up', { lng }) } href="/register"/></li>
                                    </ul>
                                </div>
                            </div>
                            <hr/>
                            <div className={style.footerCenter}>
                                <Contacts state={this.state} handleChange={this.handleChange} handleChangeOff={this.handleChangeOff} browser={browser}/>
                                <Category lng={this.props.lng}/>
                            </div>
                            <div className={style.footerBottom}>
                                <p className={style.footerBottom_copyright+ ' copyRight'}>{ i18n.t('abz.agency specially for the test task', { lng }) }</p>
                                <Lang onLanguageChanged={this.props.onLanguageChanged} lng={this.props.lng} checked={this.state.checked} browser={browser}/>
                                <Social browser={browser}/>
                            </div>
                        </footer>
                    </Container>
                </div>
            </div>
        )
    }
}

export default Footer;
